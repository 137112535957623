<template>
    <div class="modifyPriceTableNx" v-loading="loading">
        <currencyTable :table-data="tableData" ref="modifyPriceTable">
            <div slot="buttons" class="btns">
                <el-button size="mini" @click="addTasks">
                    新增
                </el-button>
                <el-button size="mini" @click="preservation">
                    保存
                </el-button>
                <el-button size="mini" @click="deleteTasks">
                    删除
                </el-button>
                <el-button size="mini" @click="dialogVisible=true">
                    生效时间设置
                </el-button>
            </div>
        </currencyTable>
        <el-dialog
            title="统一设置生效时间"
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            width="25%"
        >
            <el-date-picker
                v-model="dateTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleConfim">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';
import util from '@/utils/util';

export default {
    // 原材调价列表
    name: 'modify-price-table-nx',
    components: {
        currencyTable,
    },
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            dialogVisible: false,
            dateTime: '',
            loading: false,
            tableData: {
                isShowNo: false,
                generalQuery: false,
                showCheckBox: true,
                headerData: [{
                    title: '原材料分类',
                    field: 'type_name',
                    width: '120',
                }, {
                    title: '原材料名称',
                    field: 'child_type_name',
                    width: '120',
                }, {
                    title: '规格型号',
                    field: 'spec_name',
                    width: '120',
                }, {
                    title: '原材料品级',
                    field: 'grade_name',
                    width: '120',
                }, {
                    title: '货源地',
                    field: 'source_name',
                    width: '150',
                }, {
                    title: '供应场站',
                    field: 'station_name',
                    width: '120',
                    editType: 'text',
                }, {
                    title: '是否超吨',
                    field: 'is_exist_overweight',
                    width: '80',
                    isEdit: true,
                    editType: 'checkbox',
                }, {
                    title: '原超吨值(吨)',
                    field: 'overweight_value_before',
                    width: '120',
                }, {
                    title: '变更后超吨值(吨)',
                    field: 'overweight_value',
                    width: '160',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '原含税单价(不超吨)(元)',
                    field: 'unit_price_before',
                    width: '160',
                }, {
                    title: '变更后含税单价(不超吨)(元)',
                    field: 'unit_price',
                    width: '200',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '原含税单价(超吨)(元)',
                    field: 'overweight_price_before',
                    width: '160',
                }, {
                    title: '变更后含税单价(超吨)(元)',
                    field: 'overweight_price',
                    width: '200',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '原主材税率',
                    field: 'tax_rate_before',
                    width: '120',
                }, {
                    title: '变更后主材税率',
                    field: 'tax_rate',
                    width: '120',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '生效时间',
                    field: 'effective_date',
                    width: '200',
                    isEdit: true,
                    editType: 'item',
                }, {
                    title: '运输单位',
                    field: 'transport_name',
                    width: '200',
                }, {
                    title: '原运输单价(不超吨)(元)',
                    field: 'transport_price_before',
                    width: '200',
                }, {
                    title: '变更后运输单价(不超吨)(元)',
                    field: 'transport_price',
                    width: '200',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '原运输单价(超吨)(元)',
                    field: 'overweight_transport_price_before',
                    width: '180',
                }, {
                    title: '变更后运输单价(超吨)(元)',
                    field: 'overweight_transport_price',
                    width: '200',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '原运输税率(%)',
                    field: 'transport_rate_before',
                    width: '120',
                }, {
                    title: '变更后运输税率(%)',
                    field: 'transport_rate',
                    width: '150',
                    isEdit: true,
                    editType: 'text',
                }],
                requestUrl: `/Procurement/material/price/adjust/detailpage/${this.vueFormParams.businessInstanceId}`,
                requestParameter: '',
            },
        };
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
        handleConfim() {
            const tableData = this.$refs.modifyPriceTable.tableList;
            tableData.forEach(item => {
                item.effective_date = this.dateTime;
            });
            this.dialogVisible = false;
        },
        // 添加
        addTasks() {
            const queryString = `$frameId=${this.vueFormParams.formId}&businessInstanceId=${this.vueFormParams.businessInstanceId}`;
            this.$toast({
                title: true,
                text: '选择原材料',
                type: 'eject',
                width: '13.4rpm',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ycl_tjsq_xzhtycl',
                        ViewCode: 'grade',
                        QueryString: queryString,
                    },
                    selectCallback: this.selectRawMaterialCallback,
                },
            });
        },
        // 选择原材料回调
        selectRawMaterialCallback(data) {
            const tableData = this.$refs.modifyPriceTable.tableList;
            const newTableData = [];
            const newAddData = [];
            if (tableData.length > 0) {
                data.forEach(item => {
                    const row = tableData.filter(k => k.cmvid === item.cmvid);
                    if (row.length === 0) {
                        newTableData.push(item);
                    }
                });
            } else {
                newTableData.push(...data);
            }

            newTableData.forEach(item => {
                newAddData.push({
                    civid: item.civid,
                    cmid: item.cmid,
                    cmvid: item.cmvid,
                    rootid: item.rootid,
                    type_name: item.type_name,
                    type_code: item.type_code,
                    child_type_name: item.child_type_name,
                    child_type_code: item.child_type_code,
                    spec_code: item.spec_code,
                    spec_name: item.spec_name,
                    source_id: item.source_id,
                    source_name: item.source_name,
                    station_id: item.station_id,
                    station_name: item.station_name,
                    insurance_supply_type_before: item.insurance_supply_type,
                    insurance_supply_before: item.insurance_supply,
                    unit_price_before: item.unit_price,
                    tax_rate_before: item.tax_rate,
                    transport_id_before: item.transport_id,
                    transport_name_before: item.transport_name,
                    transport_price_before: item.transport_price,
                    transport_rate_before: item.transport_rate,
                    insurance_supply_type: item.insurance_supply_type,
                    insurance_supply: JSON.parse(JSON.stringify(item.insurance_supply)),
                    unit_price: null,
                    tax_rate: item.tax_rate,
                    transport_price: item.transport_price,
                    transport_rate: item.transport_rate,
                    effective_date: util.currentTimeFormat('yyyy-MM-dd HH:mm:ss'),
                    cutoff_date: '',
                    remarks: '',
                    type_dic_code: item.type_dic_code,
                    child_type_dic_code: item.child_type_dic_code,
                    spec_dic_code: item.spec_dic_code,
                    transport_id: item.transport_id,
                    transport_name: item.transport_name,

                    grade_code: item.grade_code,
                    grade_name: item.grade_name,
                    is_exist_overweight: Boolean(item.is_exist_overweight),
                    overweight_value_before: item.overweight_value,
                    overweight_price_before: item.overweight_price,
                    overweight_transport_price_before: item.overweight_transport_price,
                    overweight_value: item.overweight_value,
                    overweight_price: null,
                    overweight_transport_price: item.overweight_transport_price,
                });
            });
            this.$refs.modifyPriceTable.tableList.push(...newAddData);
        },
        // 保存
        preservation() {
            const row = this.$refs.modifyPriceTable.tableList;
            this.preservationRequest(row, '保存成功！');
        },
        // 保存请求
        preservationRequest(postData, text) {
            this.$axios
                .post(`/interfaceApi/Procurement/material/price/adjust/details/save/grade/${this.vueFormParams.businessInstanceId}`, postData)
                .then(() => {
                    this.$refs.modifyPriceTable.getTableData();
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除
        deleteTasks() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/Procurement/material/price/adjust/get?businessinstanceid=${this.vueFormParams.businessInstanceId}`)
                .then(res => {
                    if (res.approval_status === 2 || res.approval_status === 3) {
                        this.$message('流程已办结，不能删除。');
                    } else {
                        const data = this.$refs.modifyPriceTable.tableSelectData || [];

                        if (data.length === 0) {
                            this.$message('请选择需要删除的数据！');
                            return;
                        }
                        this.$refs.modifyPriceTable.tableSelectData.splice();
                        this.$confirm('请确认操作', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        })
                            .then(() => {

                                const ids = [];
                                data.forEach(element => {
                                    if (element.id) {
                                        ids.push(element.id);
                                    }
                                });
                                if (ids.length > 0) {
                                    this.$axios
                                        .get(`/interfaceApi/Procurement/material/price/adjust/detail/delete/${ids.join()}`)
                                        .catch(error => {
                                            this.$message.error(
                                                error.ErrorCode.Message
                                            );
                                        });
                                }
                                const tableData = this.$refs.modifyPriceTable.tableList;
                                const resultList = [];
                                tableData.forEach(item => {
                                    if (!data.includes(item)) {
                                        resultList.push(item);
                                    }
                                });
                                this.$refs.modifyPriceTable.tableList = resultList;
                                this.$message({
                                    message: '删除成功！',
                                    type: 'success',
                                });
                            })
                            .catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '取消操作',
                                });
                            });
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus">
    .modifyPriceTableNx
        height 100%

        .currencyTable
            padding 0
            border 0

            .el-table
                .cell
                    padding 0
</style>
